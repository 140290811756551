import {ProjectRequestBody, Question} from '../../api'
import axios from 'axios'
import {API_BASE_URL} from '../../constant'
import {getAuthHeader} from '../../utils/apiClient'
import {downloadFile} from '../../utils/commonUtils'

export async function createProjectApi(projectRequest: ProjectRequestBody) {
    return axios.post(`${API_BASE_URL}/projects`, projectRequest, getAuthHeader())
}

export async function putProjectApi(projectId: string, projectRequest: ProjectRequestBody) {
    return axios.put(`${API_BASE_URL}/projects/${projectId}`, projectRequest, getAuthHeader())
}

export async function downloadProjectApi(projectId: string | undefined, questionId: string | undefined) {
    if (!projectId || !questionId) {
        return
    }
    const response = await axios.post(
        `${API_BASE_URL}/projects/${projectId}/questions/${questionId}/download-urls`,
        {},
        getAuthHeader(),
    )

    const requestId = response.data

    // eslint-disable-next-line prefer-const
    let intervalId: NodeJS.Timer

    const pollEndpoint = async () => {
        try {
            const downloadResponse = await axios.get(
                `${API_BASE_URL}/projects/${projectId}/questions/${questionId}/download-urls/${requestId}`,
                getAuthHeader(),
            )
            if (downloadResponse.status === 200) {
                downloadFile(downloadResponse.data.url)
                clearInterval(intervalId)
            }
        } catch (error) {
            console.error('An error occurred:', error)
        }
    }

    // Start polling
    intervalId = setInterval(pollEndpoint, 2000)
}

export async function downloadProjectCodeBookApi(codeBookId: string | undefined) {
    if (!codeBookId) {
        return
    }
    const response = await axios.post(`${API_BASE_URL}/codebooks/${codeBookId}/download-urls`, {}, getAuthHeader())

    // eslint-disable-next-line prefer-const
    let intervalId: NodeJS.Timer

    const pollEndpoint = async () => {
        try {
            const downloadResponse = await axios.get(
                `${API_BASE_URL}/codebooks/${codeBookId}/download-urls/${response.data}`,
                getAuthHeader(),
            )
            if (downloadResponse.status === 200) {
                downloadFile(downloadResponse.data.url)
                clearInterval(intervalId)
            }
        } catch (error) {
            console.error('An error occurred:', error)
        }
    }

    // Start polling
    intervalId = setInterval(pollEndpoint, 2000)
}

export async function upsertProjectQuestionsApi(projectId: string, questionRequest: Question[]) {
    return axios.put(`${API_BASE_URL}/projects/${projectId}/questions`, questionRequest, getAuthHeader())
}

export async function recodeProjectApi(projectId: string) {
    return axios.post(`${API_BASE_URL}/projects/${projectId}/autocoding/restart`, {}, getAuthHeader())
}
